<template>
  <label :class="classes"> <slot /> </label>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        inline: this.inline,
        light: this.light,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.inline {
  display: inline-block !important;
}

.light {
  font-weight: 400 !important;
}
</style>
