<template>
  <div class="hidden" />
</template>

<script>
export default {
  props: {
    valid: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    valid: {
      immediate: true,
      handler() {
        this.$emit('update', this.valid)
      },
    },
  },
}
</script>

<style lang="less" scoped>
.hidden {
  display: none;
}
</style>
