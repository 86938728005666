<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
import jquery from 'jquery'

export default {
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    basic: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
      validator: (value) =>
        ['mini', 'tiny', 'small', 'large', 'big', 'huge', 'massive'].includes(
          value
        ) || value === null,
    },
  },
  computed: {
    classes() {
      return {
        ui: true,
        modal: true,
        basic: this.basic,
        fullscreen: this.fullScreen,
        overlay: this.overlay,
        ...(this.size ? { [this.size]: true } : {}),
      }
    },
  },
  watch: {
    closable: {
      handler() {
        this.init()
      },
    },
    autofocus: {
      handler() {
        this.init()
      },
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    jquery(this.$el).removeData()
    jquery(this.$el).remove()
  },
  methods: {
    init() {
      jquery(this.$el).modal({
        closable: this.closable,
        autofocus: this.autofocus,
        dimmerSettings: { variation: 'very light' },
        onDeny: () => {
          this.$emit('deny')
        },
        onHide: () => {
          this.$emit('hide')
        },
        onApprove: () => {
          this.$emit('approve')
        },
      })
    },
    show() {
      jquery(this.$el).modal('show')
    },
    hide() {
      jquery(this.$el).modal('hide')
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/assets/less/colors.less';

.ui.modal:not(.basic) /deep/ .actions {
  background: @white;
  border-top: none;
}
</style>
