<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    spaceBetween: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        actions: true,
        'center aligned': this.centered,
        'space-between': this.spaceBetween,
      }
    },
  },
}
</script>

<style lang="less" scoped>
.space-between {
  display: flex;
  justify-content: space-between;
}
</style>
