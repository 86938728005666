<template>
  <btn
    :type="type"
    :size="size"
    :class="classes"
    :disabled="invalid"
    :loading="loading"
    :form="formId"
    :variant="variant"
  >
    <slot />
  </btn>
</template>

<script>
import btn from '@/components/v2/btn.vue'

export default {
  components: {
    btn,
  },
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'submit',
      validator: (value) => ['submit', 'reset'].includes(value),
    },
    size: {
      type: String,
      default: 'medium',
      validator: (value) =>
        [
          'mini',
          'tiny',
          'small',
          'medium',
          'large',
          'big',
          'huge',
          'massive',
        ].includes(value),
    },
  },
  computed: {
    classes() {
      return {
        approve: this.type === 'submit',
        deny: this.type === 'reset',
      }
    },
  },
}
</script>
