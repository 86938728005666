<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    scrolling: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'center aligned': this.centered,
        image: this.image,
        scrolling: this.scrolling,
        content: true,
      }
    },
  },
}
</script>
