<template>
  <container>
    <page-header>
      <grid>
        <grid-column eight>
          <h1>Groups</h1>
        </grid-column>
        <grid-column eight right-aligned middle-aligned>
          <btn @click.native="$refs.modal.show()"> + Create Group </btn>
        </grid-column>
      </grid>
    </page-header>
    <loading v-if="pageLoading || formLoading" :loading="true" />
    <template v-else>
      <list middle-aligned divided selection size="large">
        <list-item
          v-for="group in groups"
          :key="group.id"
          :src="group.isSmartGroup ? smartGroupAvatar : defaultGroupAvatar"
          :to="{ name: 'group', params: { id: group.id } }"
        >
          <list-content>{{ group.name }}</list-content>
          <list-content class="grey members" float-right>
            {{ group.memberCount }}
            {{ group.memberCount === 1 ? 'Member' : 'Members' }}
          </list-content>
        </list-item>

        <placeholder
          v-if="groups.length === 0"
          centered
          light
          vertically-aligned
        >
          {{ placeholderText }}
        </placeholder>
      </list>
      <pagination
        v-if="groupsPagination && groupsPagination.lastPage > 1"
        :num-of-pages="groupsPagination.lastPage"
      />
    </template>
    <modal ref="modal" size="tiny" @deny="reset">
      <modal-header> Create Group </modal-header>
      <modal-content>
        <g-form id="create-group-form" @submit="create">
          <template #default="{ valid }">
            <form-validation
              :valid="valid"
              @update="(value) => (isFormValid = value)"
            />
            <form-field>
              <form-label> Name </form-label>
              <form-input
                name="Name"
                :rules="['required', 'max:255']"
                :initial-value="newGroup.name"
                placeholder="Gryffindor"
                @update="(value) => (newGroup.name = value)"
              />
            </form-field>
            <form-field>
              <form-label> Description </form-label>
              <form-input
                name="Description"
                :rules="['max:255']"
                :initial-value="newGroup.description"
                placeholder="Daring, nerve, and chivalry"
                @update="(value) => (newGroup.description = value)"
              />
            </form-field>
          </template>
        </g-form>
      </modal-content>
      <modal-actions space-between>
        <form-btn variant="default" type="reset" form-id="create-group-form">
          Cancel
        </form-btn>

        <form-btn
          variant="primary"
          type="submit"
          :invalid="!isFormValid"
          form-id="create-group-form"
        >
          Create
        </form-btn>
      </modal-actions>
    </modal>
  </container>
</template>

<script>
import { api } from '@/api'
import list from '@/components/v2/list/list.vue'
import listItem from '@/components/v2/list/item.vue'
import { toast } from '@/toasts'
import loading from '@/components/v2/loading.vue'
import container from '@/components/v2/container.vue'
import pagination from '@/components/v2/pagination/pagination.vue'
import btn from '@/components/v2/btn.vue'
import modal from '@/components/v2/modal/modal.vue'
import modalHeader from '@/components/v2/modal/header.vue'
import modalActions from '@/components/v2/modal/actions.vue'
import modalContent from '@/components/v2/modal/content.vue'
import gForm from '@/components/v2/form/form.vue'
import formField from '@/components/v2/form/field.vue'
import formInput from '@/components/v2/form/input.vue'
import formBtn from '@/components/v2/form/btn.vue'
import formValidation from '@/components/v2/form/validation.vue'
import defaultGroupAvatar from '@/assets/img/group_avatar_small.png'
import smartGroupAvatar from '@/assets/img/smart_group_avatar_small.png'
import grid from '@/components/v2/grid/grid.vue'
import gridColumn from '@/components/v2/grid/column.vue'
import placeholder from '@/components/v2/placeholder.vue'
import pageHeader from '@/components/v2/page_header.vue'
import formLabel from '@/components/v2/form/label.vue'
import listContent from '@/components/v2/list/content.vue'

export default {
  components: {
    list,
    listItem,
    loading,
    container,
    pagination,
    btn,
    modal,
    modalActions,
    modalContent,
    modalHeader,
    gForm,
    formField,
    formInput,
    formBtn,
    formValidation,
    grid,
    gridColumn,
    placeholder,
    pageHeader,
    formLabel,
    listContent,
  },
  data() {
    return {
      defaultGroupAvatar,
      smartGroupAvatar,
      groups: [],
      groupsPagination: null,
      pageLoading: false,
      formLoading: false,
      newGroup: {
        name: '',
        description: '',
      },
      isFormValid: false,
    }
  },
  computed: {
    placeholderText() {
      return "There's no groups yet, click + Create Group to make one"
    },
  },
  watch: {
    $route: {
      handler() {
        this.init()
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      try {
        this.pageLoading = true
        const response = await api.get(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`,
          {
            page: parseInt(this.$route.query.page || 1, 10),
            sort: 'isSmartGroup-desc,name,id',
            memberCount: 1,
            userStatus: ['active', 'onboarding'],
          }
        )
        this.groups = response.data
        this.groupsPagination = response.cursor
      } catch (error) {
        toast.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    reset() {
      this.newGroup = {
        name: '',
        description: '',
      }
    },
    async create(submission) {
      try {
        this.formLoading = true
        const result = await api.post(
          `${process.env.VUE_APP_DB_ENDPOINT}/v2/groups`,
          {
            name: submission.get('Name'),
            description: submission.get('Description'),
            organizationId: this.$store.state.organizationId,
            groupType: 'group',
            isSmartGroup: false,
          }
        )
        this.$router.push({
          name: 'group',
          params: {
            id: result.data.id,
          },
        })
      } catch (error) {
        toast.error(error)
      } finally {
        this.formLoading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/colors.less';

.ui.list {
  .item {
    .content.members {
      margin-top: 0.35em;
    }
  }
}
</style>
